import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"

import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import Accordion from "../../../components/Accordion"

import aboutUsImg from "../../../assets/images/patientsAtTheHeartIcon.png"
import bptw from "../../../assets/images/Great_Place_To_Work.png"

import headerHero from "../../../assets/images/patientsAtTheHeartHero.png"
import CalloutBlock from "../../../components/CalloutBlock"
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"
import Cate from "../../../assets/images/Cate_McCanless.png"

import "./index.scss"
import "../../../scss/_carouseloverride.scss"

const pageTitle = "Patients at the Heart | Harmony Biosciences"
const pageDescription =
  "Patients at the Heart supports projects for those with rare neurological disorders with excessive daytime sleepiness, as well as those with Fragile X syndrome."
const pageKeywords = ""

const currentVideo = {
  wistiaID: "1in2y73bsg",
}

class ourCulture extends Component {
  constructor() {
    super()
  }

  dreamBigBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "#NarcolepsyDreamBigWalk"
      )
    }
    window.open(
      "https://www.linkedin.com/feed/topic/?keywords=%23NarcolepsyDreamBigWalk",
      "_blank"
    )
  }

  learnMoreBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "Learn More - Culture"
      )
    }
    window.open("https://lnkd.in/d59_6eX", "_blank")
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200069/Oct 2022">
        <div id="our-culture">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/about-us/our-culture"
          />
          <Hero bg={headerHero}>
            <h1>
              <strong>Patients at the Heart</strong>
            </h1>
          </Hero>
          <ContentBlock id="patients-at-the-heart" color="blue">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={aboutUsImg} className="" style={{paddingTop: "20px"}}/>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={10}>
                <h2>
                  <strong>Patients at the Heart</strong> Program
                </h2>
                <p>
                  Harmony Biosciences' <strong>Patients at the Heart</strong>{" "}
                  program underscores our commitment to supporting initiatives that help address the unique needs of individuals with rare neurological disorders experiencing excessive daytime sleepiness, as well as individuals living with Fragile X syndrome.
                </p>
                <p>
                  <strong>Patients at the Heart</strong> awards are presented to nonprofit organizations that are working to support and address the challenges of people affected by these conditions.
                </p>
                <p><strong>Harmony Biosciences is pleased to announce the following open application period for this year’s Patients at the Heart program.</strong></p>

                <ul className="list-with-extra-spacing patients-ul"><li><strong>February 26 – May 24:</strong> Notification sent to awardees by July 2024 </li></ul>

                <h2 className="gray" style={{color:"#636569"}}>TO APPLY FOR FUNDING, CLICK <a className="green" target="_blank" href="https://harmony.medcompli.com/">HERE</a>.</h2>
                <p>
                <ol className="patients-ol relative ml-25">
                  <li className="first">Click "Patients at the Heart"</li>
                  <li className="second">Application materials, including submission and budget form, are available via this portal</li>
                  <li className="third">If applying from a 501(c) organization, kindly include your 501(c)(3) tax-deductible letter and your organization’s most recent Form 990</li>
                </ol>
                </p>

                <p>
                  Should you have any questions about portal navigation or specific questions about applications, please refer to the following email address:
                </p>
                <p>
                  <ul className="patients-ul list-with-extra-spacing">
                    <li>
                      <a
                        href="mailto:patientsattheheart@harmonybiosciences.com"
                        target="_blank"
                      >
                        patientsattheheart@harmonybiosciences.com
                      </a>
                    </li>
                  </ul>
                </p>


                <Accordion
                  title={"View More Program Details"}
                  injectHTML={false}
                >
                  <div>
                  <p>
                    <strong>
                      During the open application period in 2024, Harmony Biosciences will accept applications that focus on one or more of the following categories:
                    </strong>
                  </p>
                  </div>
                  <ul class="list-with-spacing">
                    <li>
                      Programs that help individuals with rare neurological disorders experiencing excessive daytime sleepiness, or individuals living with Fragile X syndrome, live fuller, more engaging lives
                    </li>
                    <li>
                      Programs that address the psychosocial challenges faced by individuals with these conditions, or their caregivers
                    </li>
                    <li>
                      Programs that improve overall support for family members of individuals affected by these conditions
                    </li>
                    <li>
                      Creation of educational materials to increase awareness and decrease the time to diagnose these conditions
                    </li>
                  </ul>
                  <p>
                    <strong>Funding Application Information</strong>
                  </p>
                  <div>
                    <strong>
                      Application submission should include detailed information
                      on:
                    </strong>
                  </div>
                  <ul class="list-with-spacing">
                    <li>Description and type of organization</li>
                    <li>Program title and summary</li>
                    <li>Key deliverables and program milestones</li>
                    <li>Evaluation plan</li>
                    <li>Communication and results plan</li>
                    <li>Financial information/detailed budget for project</li>
                  </ul>
                  <p>
                    <strong>
                      Harmony Biosciences will request a program update one year
                      after award.
                    </strong>
                  </p>
                  <div>
                    <strong>Additional details:</strong>
                  </div>

                  <ul class="list-with-spacing">
                    <li>
                      Applicants must be a{" "}
                      <strong>
                        501(c)(3) or similarly credentialed nonprofit
                        organization
                      </strong>
                    </li>
                    <li>
                      No minimum funding amount; maximum funding request per
                      application: $50,000
                    </li>
                  </ul>
                  <p>
                    <strong>
                      Incomplete proposals will not be considered for review.
                    </strong>
                  </p>
                  <p>
                    Applications will be reviewed by a cross-functional team of
                    Harmony Biosciences employees. Proposals will be evaluated
                    based on a standard set of review criteria, which will
                    include the quality of the application, creativity of the
                    solution, and the organization's infrastructure and past
                    program successes.
                  </p>
                  <div>
                    <p>
                      Harmony Biosciences cannot fund projects that will provide
                      a benefit to Harmony Biosciences other than the
                      "incidental and tenuous" benefit that Harmony Biosciences
                      receives in the form of goodwilI, name recognition, and
                      publicity.
                    </p>
                    <p>
                      <strong>
                        We will not consider proposals that include any of the
                        following:
                      </strong>
                    </p>
                  </div>
                  <ul class="list-with-spacing">
                    <li>
                      Programs that reference Harmony Biosciences products or
                      product candidates, or seek to measure the impacts of
                      Harmony Biosciences products
                    </li>
                    <li>
                      Organizations that discriminate on the basis of age, race,
                      sex, religion, sexual orientation, or disability
                    </li>
                    <li>
                      Funding for events or programs that have already occurred
                    </li>
                    <li>
                      Political organizations, campaigns, or direct lobbying
                      activities
                    </li>
                    <li>
                      Religious organizations or groups whose activities are
                      primarily sectarian in purpose
                    </li>
                    <li>
                      Programs or proposals that supplement an organization's
                      income or offset its expense in a manner that benefits,
                      directly or indirectly, a healthcare provider's
                      compensation
                    </li>
                  </ul>
                  <p class="footnote-small">
                    Harmony reserves the right to withdraw this program prior to selecting and making award decisions.
                    <br />
                    Harmony reserves the right to use awardee organizations and winning programs in media materials.
                    <br />
                    Harmony reserves the right to partially fund program
                    requests.
                    <br />
                    Applications and support materials must be submitted in
                    English.
                  </p>
                </Accordion>
 <Accordion
                  title={"Frequently Asked Questions"}
                  injectHTML={false}
                >
                  <div>
                  <p>
                    <strong>Can I submit more than one application?</strong>
                  </p>
                  </div>
                  <p>
                    Yes. Each program application must be submitted separately.
                  </p>
                  <div>
                  <p>
                    <strong>
                      Can my organization apply for funding in collaboration
                      with another organization?
                    </strong>
                    </p>
                  </div>
                  <p>
                    Yes. Collaborative proposals between individuals and/or
                    advocacy/community groups and/or academic institutions
                    and/or medical societies are allowed.
                  </p>
                  <div>
                  <p>
                    <strong>
                      Are local and regional programs being considered?
                    </strong>
                    </p>
                  </div>
                  <p>
                    Yes. Local, regional, and national programs will be
                    considered. At this time, the{" "}
                    <strong>Patients at the Heart</strong> funding will be
                    awarded to programs in the U.S. only.
                  </p>
                  <div>
                  <p>
                    <strong>
                      Is there any guarantee of continuation of funding after
                      awarded?
                    </strong>
                    </p>
                  </div>
                  <p>
                    No. There is no guarantee an awarded program will receive
                    funding in the future, even upon the continuation of a
                    proposed program and beyond the proposal description.
                  </p>
                  <div>
                  <p>
                    <strong>
                      May I submit a request for an activity that has already
                      occurred?
                    </strong>
                    </p>
                  </div>
                  <p>
                    No, <strong>Patients at the Heart</strong> does not fund
                    activities that have already occurred but will consider
                    support for ongoing and existing programs.
                  </p>
                  <div>
                  <p>
                    <strong>
                      What is the maximum budget that can be allocated for incidental costs?
                    </strong>
                    </p>
                  </div>
                  <p>
                   Administrative or Indirect Costs must be limited to 20% of Harmony Funding Request.
                  </p>


                  <div>
                  <p>
                    <strong>
                     Can budget provided by Harmony be used for travel costs? </strong>
                    </p>
                  </div>
                  <p>
                   Funding support cannot be used for costs of travel, lodging, or personal expenses.
                  </p>
                  <div>

                  <p>
                    <strong>
                      I have a detailed question about my proposal. Who do I
                      contact with a question?
                    </strong>
                    </p>
                  </div>
                  <p>
                    Please address all questions regarding your application to{" "}
                    <a href="mailto:patientsattheheart@harmonybiosciences.com">
                      patientsattheheart@harmonybiosciences.com
                    </a>
                    .
                  </p>
                </Accordion>



              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock id="learn" color="orange">
            <Row>
              <Col xs={12} md={12}>
                <div className="pl-20">
                  <h2>2023 Program Awards</h2>
                  Over the past five years,{" "}
                  <strong>Patients at the Heart</strong> has supported 24 initiatives, including:
                  <br />
                  <br />
                  <p>
                    <strong>
                      <a href="https://klsfoundation.org/" target="_blank">
                        Kleine-Levin Syndrome Foundation
                      </a>
                      <br />
                      Awareness & Patient Support Progression
                    </strong>
                    <br />
                    This initiative builds educational and support resources for
                    people with Kleine-Levin syndrome (KLS) and the broader
                    scientific community by creating a new ambassador program
                    that connects patients, caregivers, educators, and
                    professionals to enhance care.
                  </p>
                  <p>
                    <strong>
                      <a href="https://www.pwsausa.org/" target="_blank">
                        Prader‐Willi Syndrome Association | USA
                      </a>
                      <br />
                      PWS Sleep Within Reach Resources
                    </strong>
                    <br />
                    This program educates healthcare professionals, caregivers
                    of people with Prader-Willi syndrome (PWS), as well as
                    residential and educational staff on the most misunderstood
                    forms of sleep disorders, including those among people with
                    PWS.
                  </p>
                  <p>
                    <strong>
                      <a href="https://project-sleep.com/" target="_blank">
                        Project Sleep
                      </a>
                      <br />
                      Social Support Survey Publication & Dissemination
                    </strong>
                    <br />
                    In April 2021, Project Sleep conducted a global survey on
                    the social experiences of individuals with narcolepsy and
                    idiopathic hypersomnia (IH). With over 1,200 responses, this
                    project will enable them to analyze and publish academic
                    findings about psycho-social issues. Survey results have the
                    potential to shape future social support programs and
                    improve health outcomes for those with narcolepsy and IH.
                  </p>
                  <p>
                    <strong>
                      <a href="https://sleepconsortium.org/" target="_blank">
                        Sleep Consortium
                      </a>
                      <br />
                      Empowering Next Generation Advocates: Virtual Conference
                      and Intro to EL-PFDD Initiative
                    </strong>
                    <br />
                    This initiative entails preparing a letter of intent for FDA
                    submission to host an Externally-Led Patient-Focused Drug
                    Development (EL-PFDD) meeting for IH. They will organize a
                    webinar for people with central disorders of hypersomnolence
                    and their families, featuring expert sessions on diagnosis,
                    management, advocating amid a changing research landscape,
                    supporting future research, and concluding with progress
                    made on the EL-PFDD.
                  </p>
                  <p>
                    <strong>
                      <a href="https://swhr.org/" target="_blank">
                        Society for Women’s Health Research (SWHR)
                      </a>
                      <br />
                      The Impact of Narcolepsy on Women’s Health Across the
                      Lifespan: Women as Caregivers
                    </strong>
                    <br />
                    This program aims to combat stigma and delayed diagnosis of
                    narcolepsy in children. SWHR will provide educational
                    resources empowering mothers advocating for their children
                    within K-12 school systems by addressing stigma and securing
                    necessary support.
                  </p>
                  <p>
                    Click to learn more about what programs{" "}
                    <strong>Patients at the Heart</strong> supported in{" "}
                    <a
                      href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-announces-recipients-of-2019-patients-at-the-heart/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2019
                    </a>
                    ,{" "}
                    <a
                      href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-announces-recipients-of-2020-patients-at-the-heart-grant/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2020
                    </a>
                    ,&nbsp;
                    <a
                      href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-announces-winners-of-patients-at-the-heart-and-progress"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2021
                    </a>
                    ,&nbsp;
                    <a
                      href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-announces-2022-funding-recipients-of-patients-at-the"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2022
                    </a>
                    ,&nbsp;and&nbsp;
                    <a
                      href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-showcases-the-newest-patients-at-the-heart-and-progress"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2023
                    </a>
                    .
                  </p>
                </div>
              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock id="quote1" color="gray" className="">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={Cate} alt="Cate McCanless, Chief Corporate Affairs Officer" className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <div>
                  <p class="quote-content quote-undefined">
                    “Combining empathy with innovation to improve the lives of
                    people living with rare neurological diseases is a mission
                    that drives everyone at Harmony Biosciences. We are pleased
                    to support a diverse group of organizations that have each
                    developed new and innovative patient-centric initiatives to
                    help address the unique needs of people living with rare
                    neurological diseases.”
                  </p>
                  <p class="bold">
                    Cate McCanless, Chief Corporate Affairs Officer, Harmony
                    Biosciences
                  </p>
                </div>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock id="footnotes" color="white">
            <Row>
              <Col xs={12} md={12}>
                <p class="footnote-small">
                  All awards are paid directly to the requesting organization,
                  and never to an individual. No funding is offered or provided
                  in exchange for endorsing or prescribing Harmony products or
                  for a commitment to continue prescribing Harmony products. In
                  other words, any quid pro quo is strictly prohibited.
                </p>
                <p class="footnote-small">
                  Any funding provided by Harmony will be in compliance with all
                  applicable laws, rules, and regulations.
                </p>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="white" className="last">
            <Row className={"callout-row"}>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-rose"
                  link="/funding-programs/progress-at-the-heart/"
                >
                  <h2>
                    <strong>PROGRESS AT THE HEART</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-orange"
                  link="/funding-programs/external-funding"
                >
                  <h2>
                    <strong>EXTERNAL FUNDING</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
            </Row>
            <Row className="callout-bottom">
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-green"
                  link="/funding-programs/medical-education-grants"
                >
                  <h2>
                    <strong>MEDICAL EDUCATION GRANTS</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-gray"
                  link="/funding-programs/investigator-sponsored-studies"
                >
                  <h2>
                    <strong>INVESTIGATOR SPONSORED STUDIES</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
            </Row>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default ourCulture
